




















import debounce from 'lodash.debounce';
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';

const DEBOUNCE_TIME = 200;

@Component
class FsRange extends Vue {
  protected modelDebounceHanlder: any = null;

  @Prop()
  private value: number;

  @Prop()
  private min: number;

  @Prop()
  private max: number;

  @Prop()
  private defaultValue: number;

  @Prop()
  private label: string;

  @Prop()
  private disabled: boolean;

  @Ref('range-track-overlay')
  private trackRef: HTMLDivElement;

  @Ref('range-track-default-step')
  private trackDefaultStepRef: HTMLDivElement;

  private step: number = null;

  private realValue: number = null;

  get showDefaultTick() {
    return this.defaultValue && this.defaultValue !== this.min;
  }

  get trackFillStyles() {
    const width = this.stepModel - this.defaultStep;
    const margin = this.defaultStep + (width < 0 ? width : 0);

    return {
      'width': `${Math.abs(width)}%`,
      'margin-left': `${margin}%`,
    };
  }

  get defaultStep(): number {
    return this.calcPercent(this.defaultValue || this.min, this.min, this.max);
  }

  get stepModel(): number {
    if (this.step === null) {
      return this.calcPercent(this.valueModel, this.min, this.max);
    }

    return this.step;
  }

  get valueModel(): number {
    if (this.realValue === null) {
      return +this.value || 0;
    }
    return this.realValue;
  }

  set valueModel(value: number) {
    this.realValue = Math.min(Math.max(value, this.min), this.max);
  }

  get percentModel(): string {
    if (!this.defaultValue) {
      return this.stepModel + '';
    }

    if (this.valueModel > +this.defaultValue) {
      return this.calcPercent(this.valueModel, +this.defaultValue, this.max) + '';
    }

    return -this.calcPercent(this.valueModel, +this.defaultValue, this.min) + '';
  }

  /**
   * Calculate percentage from default point and set value model or step model to avoid code duplication
   * 0% (val) is default step
   */
  set percentModel(val: string) {
    const value: number =  Math.min(Math.max(+val, (this.showDefaultTick ? -100 : 0)), 100);

    if (val === '' || isNaN(value)) {
      return;
    }

    let newValue;
    const defaultValue = (this.defaultValue !== undefined) ? +this.defaultValue : (+this.min || 0);

    if (value === 0) {
      newValue = defaultValue;
    } else if (value > 0) {
      newValue = defaultValue + (value * ((this.max - defaultValue) / 100));
    } else {
      newValue = defaultValue - (value * ((this.min - defaultValue) / 100));
    }

    this.valueModel = newValue;
    this.step = this.calcPercent(newValue, this.min, this.max);
  }

  @Watch('realValue')
  public watchRealValue() {
    if (!this.modelDebounceHanlder) {
      this.modelDebounceHanlder = debounce((debounceVal) => this.$emit('input', debounceVal), DEBOUNCE_TIME);
    }

    this.modelDebounceHanlder(this.realValue);
  }

  public resetValue() {
    this.percentModel = '0';
  }

  public onMouseDown(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    document.body.addEventListener('mousemove', this.onMouseMove);
    document.body.addEventListener('mouseup', this.onMouseUp);
  }

  public onMouseMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.calcPercentViaPosition(event.clientX);
  }

  public onMouseUp() {
    document.body.removeEventListener('mousemove', this.onMouseMove);
    document.body.removeEventListener('mouseup', this.onMouseUp);
  }

  public onTouchStart(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();

    document.body.addEventListener('touchmove', this.onTouchMove);
    document.body.addEventListener('touchend', this.onTouchEnd);
  }

  public onTouchMove(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();

    const clientX = event.touches[0] ? event.touches[0].clientX : 0;
    this.calcPercentViaPosition(clientX);
  }

  public onTouchEnd() {
    document.body.removeEventListener('touchmove', this.onTouchMove);
    document.body.removeEventListener('touchend', this.onTouchEnd);
  }

  /**
   * Calc percent
   */
  private calcPercent(value: number, min: number, max: number): number {
    return Math.round((value - min) * 100  / (max - min));
  }

  /**
   * calculate step based on current viewport {x} position
   *
   * @param {number} pageX
   * @returns {void}
   */
  private calcPercentViaPosition(clientX: number) {
    const { width, left } = this.trackRef.getBoundingClientRect();
    let percent = this.calcPercent(clientX, left, left + width);
    if (this.trackDefaultStepRef) {
      const defaultStepLeft = this.trackDefaultStepRef.getBoundingClientRect().left;
      if (clientX > defaultStepLeft) {
        percent = this.calcPercent(clientX, defaultStepLeft, left + width);
      } else {
        percent = -(100 - this.calcPercent(clientX, left, defaultStepLeft));
      }
    }

    this.percentModel = String(percent);
  }
}

export default FsRange;
