











import Vue from 'vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { longClickDirective } from 'vue-long-click';

import { Getter, Mutation } from 'vuex-class';
import { FsCanvas, CANVAS_EVENTS } from '@filestack/canvas-sdk';

// zoom step in scale
const ZOOM_STEP = 0.01;

const longClickInstance = longClickDirective({ delay: 10, interval: 50 });

@Component({
  directives: { longclick: longClickInstance },
})
class Zoom extends Vue {

  // min zoom in percents
  private minZoom: number = 1;

  // max zoom in percents
  private maxZoom: number = 1000;

  private zoomPercent: number = 0;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('isZoomLocked', { namespace: 'image' })
  private isZoomLocked: number;

  private get isMaxZoomed(): boolean {
    return this.zoomPercent >= this.maxZoom;
  }

  private get isMinZoomed(): boolean {
    return this.zoomPercent <= this.minZoom;
  }

  private onZoomChanged(zoom: number) {
    this.zoomPercent = Math.round(zoom * 100) || 0;
  }

  private zoomIn() {
    if (this.isMaxZoomed || this.isZoomLocked) {
      return;
    }

    this.canvas.view.zoomInOut(Number((this.canvas.view.zoom + ZOOM_STEP).toFixed(3)));
  }



  private zoomOut() {
    if (this.isMinZoomed || this.isZoomLocked) {
      return;
    }

    this.canvas.view.zoomInOut(Number((this.canvas.view.zoom - ZOOM_STEP).toFixed(3)));
  }

  private calcZoomPercent(zoom: number) {
    return Math.round(zoom * 100);
  }

  private mounted() {
    this.canvas.on(CANVAS_EVENTS.ZOOM_CHANGE, this.onZoomChanged);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.ZOOM_CHANGE, this.onZoomChanged);
  }
}

export default Zoom;
