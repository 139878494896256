















import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';

@Component
class FsSlider extends Vue {
  private isDragging: boolean = false;
  private startX: number;
  private scrollLeft: number;

  @Ref('sliderRef')
  private sliderRef: HTMLDivElement;

  @Prop({default: false})
  private showGradient: boolean;

  private leftGradient: boolean = false;
  private rightGradient: boolean = false;

  private mounted() {
    this.handleScroll();
  }

  private onMouseDown(event: MouseEvent) {
    if (this.sliderRef.scrollWidth <= this.sliderRef.clientWidth) {
      return;
    }

    this.isDragging = true;
    this.startX = event.pageX - this.sliderRef.offsetLeft;
    this.scrollLeft = this.sliderRef.scrollLeft;
  }

  private onMouseMove(event: MouseEvent) {
    if (!this.isDragging) {
      return;
    }

    const x = event.pageX - this.sliderRef.offsetLeft;
    const walk = (x - this.startX) * 3; // scroll-fast
    this.sliderRef.scrollLeft = this.scrollLeft - walk;
  }

  private onMouseUp(event: MouseEvent) {
    this.isDragging = false;
  }

  private handleScroll() {
    if (!this.showGradient) {
      this.leftGradient = false;
      this.rightGradient = false;
      return;
    }

    if (this.sliderRef.scrollLeft === 0) {
      this.leftGradient = false;
    } else {
      this.leftGradient = true;
    }

    if (this.sliderRef.scrollLeft + this.sliderRef.offsetWidth >= this.sliderRef.scrollWidth) {
      this.rightGradient = false;
    } else {
      this.rightGradient = true;
    }
  }
}

export default FsSlider;
