export const adjustMenuPosition = (el: Element) => {
  const mainEl = el as HTMLElement;

  // bottom menu item handler
  const barActiveItem = mainEl.querySelector('.fs-footer .fs-bottom-bar .fs-active') as HTMLElement;
  const bottomMenuHandler = mainEl.querySelector('.fs-footer .fs-bottom-menu') as HTMLElement;
  const modalBounds = mainEl.querySelector('.fs-main-wrapper').getBoundingClientRect();

  if (!bottomMenuHandler || !barActiveItem) {
    return;
  }

  const barActiveItemBounds = getRelativeBounds(barActiveItem, mainEl.querySelector('.fs-main-wrapper'));
  const bottomMenuBounds = bottomMenuHandler.getBoundingClientRect();

  const left = barActiveItemBounds.left + (barActiveItemBounds.width / 2) - bottomMenuBounds.width / 2;

  if ((modalBounds.left >= bottomMenuBounds.left + left) || modalBounds.left + modalBounds.width <= bottomMenuBounds.left + bottomMenuBounds.width + left) {
    bottomMenuHandler.classList.add('full-width');
    bottomMenuHandler.style.left = null;
  } else {
    bottomMenuHandler.classList.remove('full-width');
    bottomMenuHandler.style.left = `${left}px`;
  }

};

export const getRelativeBounds = (el: HTMLElement, to: HTMLElement) => {
  const toBounds = to.getBoundingClientRect();
  const elBounds = el.getBoundingClientRect();

  return {
    width: elBounds.width,
    height: elBounds.height,
    left: elBounds.left - toBounds.left,
    top: elBounds.top - toBounds.top,
  };
};
