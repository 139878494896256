













import Vue from 'vue';
import { Adjustments } from '@filestack/canvas-sdk';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Gamma extends FilterMixin {

  protected filter = new Adjustments.Gamma();

  protected value: any = { gamma: [1, 1, 1] };

  get inputValue() {
    return this.value.gamma;
  }

  private onChange(newVal: any, idx: number) {
    Vue.set(this.value.gamma, idx, newVal);
  }
}

export default Gamma;
