import { CANVAS_EVENTS, FilterPayload, FsCanvas } from '@filestack/canvas-sdk';
import debounce from 'lodash.debounce';
import Vue from 'vue';
import { Component, Inject, Watch } from 'vue-property-decorator';

// You can declare a mixin as the same style as components.
const DEBOUNCE_TIME = 500;
@Component
export class FilterMixin extends Vue {
  protected filterDebounceHandler: any;

  protected value: any;

  protected filter: any;

  private isInitialized: boolean = false;

  protected get id() {
    return this.filter.getId();
  }

  @Inject('canvas')
  private canvas: FsCanvas;

  protected mounted() {
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onUndo);
    // @ts-ignore
    this.updateValue();
  }

  protected destroy() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.onUndo);
  }

  protected getFilter(): FilterPayload {
    return this.canvas.filters.getItemsById(this.id);
  }

  protected updateValue() {
    const item = this.getFilter();
    this.value = item ? item.value : { ...this.value };
  }

  protected resetFilter(): void {
    return this.canvas.filters.deleteById(this.id);
  }

  @Watch('value', { deep: true })
  protected onValueChange() {
    // first update value will trigger this if
    if (!this.isInitialized) {
      this.isInitialized = true;
      return;
    }

    this.filter.setValue({...this.value});

    this.canvas.filters.putItem(this.filter.getPayload());
    this.canvas.filters.render().then(() => {
      if (!this.filterDebounceHandler) {
        this.filterDebounceHandler = debounce(() => {
          this.canvas.saveState();
        }, DEBOUNCE_TIME);
      }

      this.filterDebounceHandler();
    });
  }

  private onUndo() {
    this.updateValue();
  }
}
