









import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';
import { Client } from 'filestack-js';

import { ToastMessage, ToastMessageType, ViewMode } from '@/types';
import { FsCanvas, CANVAS_EVENTS } from '@filestack/canvas-sdk';

@Component({
  name: 'FsTransformsUi',
})
class App extends Vue {

  @Provide('canvas')
  private canvas: FsCanvas;

  @Provide('sdk')
  private sdk: Client;

  @Getter('isBlocked')
  private isAppBlocked: boolean;

  @Mutation('toast/show')
  private showToast: (msg: ToastMessage) => void;

  @Mutation('image/setReadonly')
  private setReadonly: (msg: boolean) => void;

  @Getter('viewMode')
  private viewMode: ViewMode;

  protected get isInline() {
    return this.viewMode === ViewMode.INLINE;
  }

  protected beforeCreate() {
    // @ts-ignore
    this.canvas = this.$root.canvasInstance;

    // @ts-ignore
    this.sdk = this.$root.sdk;
  }

  protected mounted() {
    this.$emit('canvas:ready', this.canvas);
    this.canvas.on(CANVAS_EVENTS.READONLY, () => {
      this.setReadonly(true) ;

      this.showToast({
        title: 'readonly.title',
        message: 'readonly.message',
        type: ToastMessageType.INFO,
      });
    });
  }

  protected destroy() {
    this.canvas.off(CANVAS_EVENTS.READONLY);
  }
}

export default App;
