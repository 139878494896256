import './assets/styles/default.scss';

import App from '@/App.vue';
import FsButton from '@/components/Button.vue';
import FsRangeFilterWrapper from '@/components/FilterRangeWrapper.vue';
import FsRange from '@/components/Range.vue';
import FsSlider from '@/components/Slider.vue';
import i18n from '@/i18n';
import { store } from '@/store';
import { CANVAS_EVENTS, FsCanvas } from '@filestack/canvas-sdk';
import { FILESTACK_MODULES, loadModule } from '@filestack/loader';
import { Client, PrefetchEvents, PrefetchResponse } from 'filestack-js';
import cloneDeep from 'lodash.clonedeep';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex, { Store } from 'vuex';

import { debounce } from './directives/debounce';
import { isActive } from './helpers/router';
import { routes } from './router';
import { EditorConfig, RootState, ViewMode } from './types';

const SDK_PATH = `${location.protocol.indexOf('https') > -1 ? `https` : 'http'}://static.filestackapi.com/filestack-js/3.x.x/filestack.min.js`;

Vue.config.productionTip = false;

Vue.use(VTooltip, {
  popover: {
    defaultContainer: '.fs-main',
    defaultClass: 'fs-popover-theme',
    defaultBaseClass: 'fs-tooltip fs-popover',
  },
});

Vue.directive('fs-debounce', debounce);
Vue.component('fs-button', FsButton);
Vue.component('fs-range', FsRange);
Vue.component('fs-range-filter-wrapper', FsRangeFilterWrapper);
Vue.component('fs-slider', FsSlider);

Vue.use(VueRouter);

/**
 * Main Filestack Transform ui class
 *
 * @example
 * const tr = new FilestackTransform();
 * tr.open('IMAGE_URL').then((result) => {
 *  console.log(result);
 * });
 *
 * @class FilestackTransform
 */
class FilestackTransform {
  private instance: FsCanvas;

  /**
   * Config handle
   *
   * @private
   * @type {EditorConfig}
   * @memberof FilestackTransform
   */
  private config: EditorConfig;

  /**
   * App handle
   *
   * @private
   * @type {Vue}
   * @memberof FilestackTransform
   */
  private app: Vue;

  /**
   * Store instance
   *
   * @private
   * @type {Store<RootState>}
   * @memberof FilestackTransform
   */
  private store: Store<RootState>;

  /**
   * Router instance
   *
   * @private
   * @type {VueRouter}
   * @memberof FilestackTransform
   */
  private router: VueRouter;

  /**
   * Determine view mode for ui (overlay|inline)
   *
   * @private
   * @type {ViewMode}
   * @memberof FilestackTransform
   */
  private viewMode: ViewMode = ViewMode.OVERLAY;

  /**
   * mount container
   *
   * @private
   * @type {HTMLElement | Element}
   * @memberof FilestackTransform
   */
  private container: HTMLElement | Element;

  /**
   * Filestack sdk handler
   *
   * @private
   * @type {Client}
   * @memberof FilestackTransform
   */
  private sdk: Client;
  /**
   * Filestack Loader psomise
   *
   * @private
   * @type {Promise<any>}
   * @memberof FilestackTransform
   */
  private loaderPromise: Promise<any>;

  /**
   * Creates an instance of FilestackTransform.
   *
   * @param  {string} apikey
   * @param  {EditorConfig} [config]
   * @memberof FilestackTransform
   */
  constructor(apikey: string | Client, config?: EditorConfig) {
    this.config = config;

    this.updateAppHeight();

    this.store = new Vuex.Store<RootState>(cloneDeep(store));
    this.instance = new FsCanvas();

    if (typeof apikey === 'string') {
      this.loaderPromise = loadModule(FILESTACK_MODULES.FILESTACK_SDK, SDK_PATH).then((Filestack: any) => {
        this.sdk = Filestack(apikey, config && config.client ? config.client : null);
        this.loaderPromise = null;

        return this.sdk;
      });
    } else {
      this.sdk = apikey;
    }
  }

  /**
   * SEt container for transforms application
   *
   * @param {(string | HTMLElement | Element)} el
   * @returns
   * @memberof FilestackTransform
   */
  public setContainer(el: string | HTMLElement | Element, viewMode: ViewMode = ViewMode.OVERLAY): FilestackTransform {

    this.viewMode = viewMode;

    if (el instanceof HTMLElement || el instanceof Element) {
      this.container = el;
      return;
    }

    this.container = document.querySelector(el);

    return this;
  }

  /**
   * Merge config object with default
   *
   * @param {EditorConfig} config
   * @returns {FilestackTransform}
   * @memberof FilestackTransform
   */
  public setConfig(config: EditorConfig): FilestackTransform {
    this.config = config;

    return this;
  }

  /**
   * Sets single config key (key can be )
   *
   * @param {string} key - The path of the property to set.
   * @param {*} value
   * @returns {this}
   * @memberof FilestackTransform
   */
  public setConfigKey(key: string, value: any): FilestackTransform {
    this.store.commit('setConfigKey', { key, value });

    return this;
  }

  /**
   * Changetransfroms source @todo add support for blob | file
   *
   * @param {(string | File | Blob)} source
   * @returns
   * @memberof FilestackTransform
   */
  public setSource(source: string | File | Blob) {
    if (!source) {
      return;
    }

    this.store.commit('setSource', source);
  }

  /**
   * Opens transfroms ui
   *
   * @param {(string | File | Blob)} [source]
   * @returns {(Promise<string | Blob>)}
   * @memberof FilestackTransform
   */
  public open(source?: string | File | Blob): Promise<string | Blob> {
    if (!this.instance) {
      this.instance = new FsCanvas();
    }

    window.addEventListener('resize', this.updateAppHeight);
    window.addEventListener('orientationchange', this.updateAppHeight);

    this.setSource(source);

    return new Promise((resolve, reject) => {
      const makePrefetch = () => {
        return this.sdk
          .prefetch({
            permissions: ['transforms_ui'],
            events: [PrefetchEvents.TRANSFORM_UI],
          })
          .then((res: PrefetchResponse) => {
            this.initInstance();

            if (this.config) {
              this.store.commit('setConfig', this.config);
            }

            if (this.viewMode) {
              this.store.commit('setViewMode', this.viewMode);
            }

            if (!store.getters.source) {
              throw new Error('Source image not set. Exiting');
            }

            const appElement = document.createElement('div');

            this.container.appendChild(appElement);

            this.app.$mount(appElement);
            this.store.commit('setPrefetched', res);

            this.router.replace('/').catch((e) => '');

            this.instance.on(CANVAS_EVENTS.SAVED, (file: string | Blob) => {
              if (this.app.$store.getters.closeOnSave) {
                this.close();
                resolve(file);
              }
            });

            this.app.$root.$once('destroy', this.close.bind(this));
          })
          .catch(reject);
      };

      if (!store.getters.source) {
        throw new Error('Source image not set. Exiting');
      }


      if (!this.container || !document.body.contains(this.container)) {
        this.container = document.createElement('div');
        this.container.classList.add('fs-transforms-container');
        document.body.appendChild(this.container);
      }

      if (this.loaderPromise) {
        return this.loaderPromise.then(() => makePrefetch());
      }

      makePrefetch();
    });
  }

  /**
   * Close the ui
   *
   * @memberof FilestackTransform
   */
  public close() {
    window.removeEventListener('resize', this.updateAppHeight);
    window.removeEventListener('orientationchange', this.updateAppHeight);

    this.app.$destroy();
    this.app.$el.parentNode.removeChild(this.app.$el);
    this.router = null;
    this.instance = null;
    this.app = null;
  }

  /**
   * Add event listener to editor
   *
   * @param {(CANVAS_EVENTS)} ev
   * @param {Function} cb
   * @returns
   * @memberof FilestackTransform
   */
  public on(ev: CANVAS_EVENTS, cb: any) {
    return this.instance.on(ev, cb);
  }

  /**
   * Remove event listener from editor
   *
   * @param {(CANVAS_EVENTS)} ev
   * @param {Function} cb
   * @returns
   * @memberof FilestackTransform
   */
  public off(ev: CANVAS_EVENTS, cb: any) {
    return this.instance.off(ev, cb);
  }

  /**
   * Same as one but fires only once
   *
   * @param {(CANVAS_EVENTS)} ev
   * @param {Function} cb
   * @returns
   * @memberof FilestackTransform
   */
  public once(ev: CANVAS_EVENTS, cb: any) {
    return this.instance.once(ev, cb);
  }

  public getSdkClient(): Promise<Client> {
    if (this.loaderPromise) {
      return this.loaderPromise;
    }

    return Promise.resolve(this.sdk);
  }

  private updateAppHeight() {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
  }

  /**
   * Reset vue instance and state
   *
   * @private
   * @returns
   * @memberof FilestackTransform
   */
  private initInstance() {
    if (this.app) {
      return;
    }

    if (!this.router) {
      this.router = new VueRouter({
        routes,
        mode: 'abstract',
      });
    }

    this.router.beforeEach((to: any, _: any, next: any) => {
      if (to.meta && to.meta.enabledEntry) {
        return next(isActive(to.meta, this.store.getters.config));
      }

      next();
    });

    this.app = new Vue({
      data: {
        canvasInstance: this.instance,
        sdk: this.sdk,
      },
      store: this.store,
      i18n,
      router: this.router,
      render: (h) => h(App),
    });
  }
}

// auto install
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.FilestackTransform = FilestackTransform;
}

export { FilestackTransform };
export * from './types';
