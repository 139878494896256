export const fillRange = (start: number, end: number, step: number) => {
  let index = -1;

  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);

  const result = new Array(length);

  while (length--) {
    result[++index] = start;
    start += step;
  }

  return result;
};

export const numbersSequence = (max: number, nth: number) => {
  return Array.from({ length: max }, (_: number, k: number) => k + 1).filter((_: number, i: number) => i % nth === nth - 1);
};
