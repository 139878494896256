import { DirectiveOptions } from 'vue';

const debounceFn = (fn: any, delay: number) => {
  let timeout: any = null;

  return (...args: any) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

export const debounce: DirectiveOptions = {
  inserted(el: any, node: any) {
    if (node.value !== node.oldValue) { // change debounce only if interval has changed
      el.oninput = debounceFn((evt: any) => {
        el.dispatchEvent(new Event('change'));
      }, parseInt(node.value, 2) || 500);
    }
  },
};
