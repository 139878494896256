























































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Inject, Watch } from 'vue-property-decorator';
import debounce from 'lodash.debounce';

import { fillRange, numbersSequence } from '@/helpers/array';
import { FontLoader } from '@/helpers/fonts';
import { FontFace } from '@/types';
import { FsCanvas, TextSettings, TextLayer, CANVAS_EVENTS, AbstractLayer } from '@filestack/canvas-sdk';
import isequal from 'lodash.isequal';
import ColorPicker from '@/components/ColorPicker.vue';

const DEBOUNCE_TIME = 250;

@Component({
  components: {
    ColorPicker,
  },
})
class TextTool extends Vue {
  protected settingsDebounceHandler: any;
  protected layerDebounceHandler: any;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  private evHandler: any;

  private selected: TextLayer;

  protected get sizes(): number[] {
    return fillRange(4, 200, 2);
  }

  protected get fonts(): FontFace {
    return this.config('editor.text.fonts');
  }

  protected get numbersSequence() {
    return numbersSequence;
  }

  private get defaultFont() {
    return this.config('editor.text.fonts').find((el: any) => el.default);
  }

  private get defaultSettings(): TextSettings {
    return {
      ...this.settingsModel,
      ...this.config('editor.text.defaults'),
      font: this.defaultFont.family,
    };
  }

  private settingsModel: TextSettings = {
    size: null,
    font: null,
    bold: false,
    underline: false,
    italic: false,
    backgroundColor: null,
    color: null,
    align: 'left',
    opacity: 100,
  };

  private getLayerSettings(textLayer: AbstractLayer): TextSettings {
    if (textLayer && textLayer instanceof TextLayer) {
      return textLayer.getSettings();
    }

    return this.defaultSettings;
  }

  private mounted() {
    //  @ts-ignore
    this.selected = this.$route.params.selected;
    Vue.set(this, 'settingsModel', this.getLayerSettings(this.selected));

    this.evHandler = this.handleKeyboard.bind(this);

    FontLoader(this.config('editor.text.fonts')).then(() => {
      if (!this.selected) {
        this.canvas.layer.add(new TextLayer(this.settingsModel));
      }
    });

    this.canvas.on(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);
    this.canvas.on(CANVAS_EVENTS.LAYER_MODIFIED, this.onLayerModified);
    document.addEventListener('keydown', this.evHandler);
  }

  private beforeDestroy() {
    this.canvas.off(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);
    this.canvas.off(CANVAS_EVENTS.LAYER_MODIFIED, this.onLayerModified);
    document.removeEventListener('keydown', this.evHandler);
  }

  private handleKeyboard(e: KeyboardEvent) {
    // do not catch other keys
    // backspace and delete -> remove selected text
    if ([8, 46].indexOf(e.keyCode) === -1) {
      return;
    }

    // @ts-ignore
    if (e && e.target && ['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) > -1) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    // @ts-ignore
    this.canvas.layer.remove(this.selected);
    this.canvas.saveState('textRemove');
    this.$router.push('/');
  }

  @Watch('settingsModel', { deep: true })
  private onSettingsUpdate() {
    if (this.selected && !isequal(this.selected.getSettings(), this.settingsModel)) {
      this.selected.setSettings(this.settingsModel);
      this.settingsDebounce();
    }
  }

  private settingsDebounce() {
    if (!this.settingsDebounceHandler) {
      this.settingsDebounceHandler = debounce(() => {
        if (this.selected) {
          this.canvas.saveState('textSettingsUpdate');
        }
      }, DEBOUNCE_TIME);
    }

    this.settingsDebounceHandler();
  }

  private selectionChangedCallback(data: TextLayer) {
    this.selected = data;
    Vue.set(this, 'settingsModel', this.getLayerSettings(data));
  }

  private onLayerModified() {
    if (!this.layerDebounceHandler) {
      this.layerDebounceHandler = debounce(() => {
          this.canvas.saveState('layerModified');
      }, DEBOUNCE_TIME);
    }

    this.layerDebounceHandler();
  }

  private isEnabled<T extends keyof TextSettings>(key: T, value: TextSettings[T]) {
    return this.settingsModel[key] === value;
  }

  private setFormat<T extends keyof TextSettings>(key: T, value: TextSettings[T]) {
    if (typeof this.settingsModel[key] === 'undefined') {
      return;
    }

    Vue.set(this.settingsModel, key, value);
  }
}

export default TextTool;
