













































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Inject } from 'vue-property-decorator';

import { FsCanvas, BorderSettings, CANVAS_EVENTS } from '@filestack/canvas-sdk';
import ColorPicker from '@/components/ColorPicker.vue';
import { Chrome } from 'vue-color';
import { numbersSequence } from '@/helpers/array';

@Component({
  components: {
    ColorPicker,
    color: Chrome,
  },
})
class Border extends Vue {
  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  private undoHandler: any;

  private settingsModel: BorderSettings = {
    color: '#000',
    width: 20,
    opacity: 50,
  };

  protected get numbersSequence() {
    return numbersSequence;
  }

  protected get enabled() {
    return this.canvas.border.isEnabled;
  }

  protected set enabled(value: boolean) {
    if (!this.canvas.border.isEnabled && value) {
      this.canvas.border.enable({ ...this.settingsModel });
      this.canvas.saveState('borderSettingsUpdate');
      return;
    }

    this.canvas.border.remove();
    this.canvas.saveState('borderSettingsUpdate');
  }

  protected mounted() {
    this.undoHandler = () => this.resotreSettings();
    if (this.canvas.border.isEnabled) {
      this.resotreSettings();
    }

    this.canvas.on(CANVAS_EVENTS.UNDO, this.undoHandler);
  }

  protected destroyed() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.undoHandler);
  }

  private resotreSettings() {
    this.settingsModel = {
      ...this.config('editor.border.defaults'),
      ...this.canvas.border.getSettings(),
    };
  }

  private onChange() {
    this.canvas.border.setSettings({...this.settingsModel});
    this.canvas.saveState('borderSettingsUpdate');
  }
}

export default Border;
