






import { Adjustments } from '@filestack/canvas-sdk';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Hue extends FilterMixin {

  protected value = { rotation: 0 };

  protected filter = new Adjustments.Hue();

}

export default Hue;
