















































import Vue from 'vue';
import { Inject, Component, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { ToastMessage, ToastMessageType } from '@/types';
import { MaskPayload, FsCanvas, CANVAS_EVENTS } from '@filestack/canvas-sdk';

@Component
class Resize extends Vue {
  private selected: MaskPayload = null;

  private resize: { width: number; height: number } = { width: null, height: null };

  private lockResizeRatio: boolean = true;

  private isCropActive = false;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  get transformConfig() {
    // @ts-ignore @todo
    return this.config('editor.transforms');
  }

  get canBeResized() {
    if (!this.resize.width || this.resize.width === 0 || !this.resize.height || this.resize.height === 0) {
      return false;
    }

    return true;
  }

  private get isResizeDisabled(): boolean {
    return this.canvas.view.size.height === this.resize.height && this.canvas.view.size.width === this.resize.width;
  }

  @Mutation('setZoomLock', { namespace: 'image' })
  private lockZoom: any;

  @Mutation('toast/show')
  private showToast: (data: ToastMessage) => void;

  private evHandler: any;

  private mounted() {
    this.lockZoom(true);
    // setup initial dimensions
    this.resize = Object.assign({}, this.canvas.view.size);

    this.canvas.on(CANVAS_EVENTS.RESIZED, this.onImageResize);
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onImageResize);
    document.addEventListener('keydown', this.evHandler);
  }

  private destroyed() {
    this.lockZoom(false);
    this.canvas.mask.destroy();

    this.canvas.off(CANVAS_EVENTS.RESIZED, this.onImageResize);
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onImageResize);
    document.removeEventListener('keydown', this.evHandler);
  }

  private onImageResize() {
    this.resize = Object.assign({}, this.canvas.view.size);
  }

  private onWidthChange(value: string): void {
    if (!value || value === '') {
      return;
    }

    this.resize.width = Math.round(+value);

    if (!this.lockResizeRatio) {
      return;
    }

    this.resize.height = Math.round(this.canvas.view.size.height * (this.resize.width / this.canvas.view.size.width));
  }

  private onHeightChange(value: string): void {
    if (!value || value === '') {
      return;
    }

    this.resize.height = Math.round(+value);

    if (!this.lockResizeRatio) {
      return;
    }

    this.resize.width = Math.round(this.canvas.view.size.width * (this.resize.height / this.canvas.view.size.height));
  }

  private resizeImage(e: any) {
    this.canvas.transform.resize(this.resize);
    this.canvas.saveState('resizeImage');
    this.$router.push({ path: '/' });

    this.showToast({
      message: 'messages.resize_success',
      type: ToastMessageType.SUCCESS,
      params: this.resize,
      icon: 'done',
    });
  }
}

export default Resize;
