import Vue from 'vue';
import { ToastMessage } from './../../types';
import { ToastState } from '@/types';

export default {
  namespaced: true,
  state: {
    messages: [],
    timeout: 3000,
  },
  getters: {
    messages: (st: ToastState) => st.messages,
    duration: (st: ToastState) => st.duration,
  },
  mutations: {
    reset: (st: ToastState) => {
      Vue.set(st, 'messages', []);
    },
    show: (st: ToastState, message: ToastMessage) => {
      st.messages.push(message);
    },
    remove: (st: ToastState, message: ToastMessage) => {
      // console.log('remiov', message, st.messages.indexOf(message);
      st.messages.splice(st.messages.indexOf(message), 1);
    },
    first: (st: ToastState) => {
      return st.messages.shift();
    },
  },
};
