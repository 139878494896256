






import { Adjustments } from '@filestack/canvas-sdk';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Contrast extends FilterMixin {

  protected filter = new Adjustments.Contrast();

  protected value = { contrast: 0 };
}

export default Contrast;
