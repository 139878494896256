export const checkImageUrl = (url: string): Promise<void> => {
  const image = new Image();

  return new Promise((resolve, reject) => {
    image.onload = () =>  {
      if (image.width > 0) {
        return resolve();
      }

      return reject();
    };

    image.onerror = reject;
    image.src = url;
  });
};
