





























import Vue from 'vue';
import { Inject, Component } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { ToastMessage, ToastMessageType } from '@/types';
import { MaskPayload, FsCanvas, CANVAS_EVENTS } from '@filestack/canvas-sdk';

@Component
class Crop extends Vue {
  private selected: MaskPayload = null;

  private resize: { width: number; height: number } = { width: null, height: null };

  private isCropActive = false;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  get transformConfig() {
    // @ts-ignore @todo
    return this.config('editor.transforms');
  }

  get canBeResized() {
    if (!this.resize.width || this.resize.width === 0 || !this.resize.height || this.resize.height === 0) {
      return false;
    }

    return true;
  }

  private get isResizeDisabled(): boolean {
    return this.canvas.view.size.height === this.resize.height && this.canvas.view.size.width === this.resize.width;
  }

  @Getter('isApplied', { namespace: 'transform' })
  private isApplied: boolean;

  @Getter('appliedCropArea', { namespace: 'transform' })
  private appliedCropArea: boolean;

  @Getter('selectedRatio', { namespace: 'transform' })
  private selectedRatio: boolean;

  @Mutation('setSelectedRatio', { namespace: 'transform' })
  private setSelectedRatio: any;

  @Mutation('setZoomLock', { namespace: 'image' })
  private lockZoom: any;

  @Mutation('setDimensions', { namespace: 'image' })
  private setDimensions: any;

  @Mutation('toast/show')
  private showToast: (data: ToastMessage) => void;

  private evHandler: any;

  private mounted() {
    this.lockZoom(true);
    // setup initial dimensions
    this.resize = Object.assign({}, this.canvas.view.size);

    this.evHandler = this.handleKeybord.bind(this);
    this.canvas.on(CANVAS_EVENTS.RESIZED, this.onImageResize);
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onImageResize);
    document.addEventListener('keydown', this.evHandler);
  }

  private destroyed() {
    this.lockZoom(false);
    this.canvas.transform.cancel();

    this.canvas.off(CANVAS_EVENTS.RESIZED, this.onImageResize);
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onImageResize);
    document.removeEventListener('keydown', this.evHandler);
  }

  private onImageResize() {
    this.resize = Object.assign({}, this.canvas.view.size);
  }

  private resizeImage() {
    this.canvas.transform.resize(this.resize);
    this.canvas.saveState('resizeImage');

    this.showToast({
      message: 'messages.resize_success',
      type: ToastMessageType.SUCCESS,
      params: this.resize,
    });
  }

  private setCropRatio(ratio: MaskPayload) {
    this.selected = ratio;
    this.canvas.transform.start({ ratio, maskPosition: this.transformConfig.maskPosition });

    this.isCropActive = true;
  }

  private async handleKeybord(e: KeyboardEvent) {
    const mask = this.canvas.mask;

    if (!mask) {
      return false;
    }

    // do not catch other keys
    if ([27, 13, 37, 38, 39, 40].indexOf(e.keyCode) === -1) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    switch (e.keyCode) {
      case 27: // ESC
        this.selected = null;
        mask.destroy();
        break;
      case 13: // k ente
        if (this.isCropActive) {
          await this.applyCrop();
          return;
        }

        this.resizeImage();
        break;
      case 37: // left
        await mask.moveBy({ left: 1 });
        break;
      case 38: // Up
        await mask.moveBy({ up: 1 });
        break;
      case 39: // right
        await mask.moveBy({ right: 1 });
        break;
      case 40: // down
        await mask.moveBy({ down: 1 });
        break;
    }

    return false;
  }

  private async applyCrop() {
    if (!this.isCropActive) {
      return;
    }

    return this.canvas.transform.save().then(() => {
      this.resize = Object.assign({}, this.canvas.view.size);
      this.isCropActive = false;
      this.selected = null;
      this.canvas.saveState('applyCrop');
      this.$router.push({ path: '/' });
    });
  }
}

export default Crop;
