import { FontFace as FontFaceInterface } from './../types';

export const FontLoader = (list: FontFaceInterface[]) => {
  const toLoad: any = [];

  list.forEach((font) => {
    if (font.url) {
      // @ts-ignore
      toLoad.push(new FontFace(font.family, `url(${font.url})`).load);
    }

    if (font.cssUrl) {
      toLoad.push(loadExternalCss(font.cssUrl));
    }
  });

  return Promise.all(toLoad);
};

const loadExternalCss = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
  });
};
