




















import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';

import Canvas from './Canvas.vue';
import Toast from './Toast.vue';
import { FsCanvas } from '@filestack/canvas-sdk';
import { Getter } from 'vuex-class';

@Component({
  components: { Canvas, Toast },
})
class Main extends Vue {

  @Inject('canvas')
  public canvas: FsCanvas;

  protected showOverlay: boolean = false;

  @Getter('isBlocked')
  protected isAppBlocked: boolean;

  protected menuGoBack($e: any) {
    if (document.body.contains(document.querySelector('.fs-menu.fs-bottom-menu')) &&
     this.$router.currentRoute.path !== '/' &&
     !$e.target.closest('a') &&
     !$e.target.closest('.fs-footer') &&
     !$e.target.closest('canvas')) {
      this.$router.push('/');
    }
  }
}

export default Main;
