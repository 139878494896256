















import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Chrome } from 'vue-color';

@Component({
  components: {
    'chrome-picker': Chrome,
  },
})
class ColorPicker extends Vue {
  @Prop() public isBorder: boolean;

  @Prop() public icon: string;

  @Model('input')
  private value: string;

  private isOpened: boolean = false;

  private onColorUpdate(col: any) {
    const rgb = col.rgba;
    this.$emit('input', `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`);
  }

  private toogleColorPicker() {
    this.isOpened = !this.isOpened;
  }
}

export default ColorPicker;
