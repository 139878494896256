import { RouteConfig } from 'vue-router';

export const getByName = (name: string, routeData: RouteConfig[]): any => {
  let found = routeData.find((el: RouteConfig) => el.name === name);

  if (found) {
    return found;
  }

  const len = routeData.length;

  for (let i = 0; i < len; i++) {
    if (!routeData[i].children) {
      continue;
    }

    found = getByName(name, routeData[i].children);

    if (found) {
      return found;
    }
  }
};

export const isActive = (meta: any, config: any): false | undefined => {
  if (meta && meta.enabledEntry) {
    let enabled: any;

    if (typeof meta.enabledEntry === 'string') {
      enabled = !!config(meta.enabledEntry) ? undefined : false;
    } else if (meta.enabledEntry.path) {
      const data = config(meta.enabledEntry.path);

      if (Array.isArray(data)) {
        enabled = data.indexOf(meta.enabledEntry.item) > -1;
      }
    }

    meta.visible = enabled;

    return enabled;
  }

  return;
};
