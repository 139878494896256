














import Vue from 'vue';
import { Inject, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CANVAS_EVENTS, FsCanvas, LAYER_POSITION_ACTION, AbstractLayer } from '@filestack/canvas-sdk';

@Component
class TopLayerMenu extends Vue {
  @Inject('canvas')
  public canvas: FsCanvas;

  @Getter('config')
  public config: any;

  private selected: AbstractLayer = null;

  get topMenuConfig() {
    // @ts-ignore @todo
    return this.config('editor.topMenu');
  }

  public selectionChangedCallback(data: AbstractLayer) {
    this.selected = data;
  }

  public mounted() {
    // @ts-ignore
    this.selected = this.$route.params.selected;
    this.canvas.on(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);
  }

  public destroyed() {
    this.canvas.off(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);
  }

  public isFirstLayer(): boolean {
    if (this.selected) {
      return this.canvas.layer.isFirstLayer(this.selected);
    }

    return true;
  }

  public isLastLayer(): boolean {
    if (this.selected) {
      return this.canvas.layer.isLastLayer(this.selected);
    }

    return true;
  }


  public enabledLayersNav(): boolean {
    if (!this.topMenuConfig.layers) {
      return false;
    }

    if (this.selected) {
      return !this.canvas.layer.isOnlyLayer;
    }

    return true;
  }

  public flip() {
    if (this.selected) {
      this.selected.flip();
      this.canvas.saveState('layerFlipX');
    }
  }

  public flop() {
    if (this.selected) {
      this.selected.flop();
      this.canvas.saveState('layerFlipY');
    }
  }

  public rotateRight() {
    if (this.selected) {
      this.selected.rotateRight();
      this.canvas.saveState('layerRotateRight');
    }
  }

  public rotateLeft() {
    if (this.selected) {
      this.selected.rotateLeft();
      this.canvas.saveState('layerRotateLeft');
    }
  }

  public changeLayerOrder(type: LAYER_POSITION_ACTION) {
    if (this.selected) {
      this.canvas.layer.changeLayerOrder(this.selected, type);
      this.canvas.saveState('layerChangeLayerOrder');
    }
  }

  public remove() {
    if (!this.selected) {
      return;
    }

    this.canvas.layer.remove(this.selected);
    this.canvas.saveState('layerRemove');
    this.$router.push('/');
  }
}

export default TopLayerMenu;
