

















import Vue from 'vue';
import { Inject, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { FsCanvas } from '@filestack/canvas-sdk';

@Component
class FlipAndRotate extends Vue {
  @Inject('canvas')
  public canvas: FsCanvas;

  @Getter('config')
  public config: any;

  get transformConfig() {
    return this.config('editor.transforms');
  }

  public async flip() {
    this.canvas.transform.flipX().then(() => {
      this.canvas.saveState('flipX');
    });
  }

  public flop() {
    this.canvas.transform.flipY().then(() => {
      this.canvas.saveState('flipY');
    });
  }

  public rotateRight() {
    this.canvas.transform.rotateRight().then(() => {
      this.canvas.saveState('rotateRight');
    });
  }

  public rotateLeft() {
    this.canvas.transform.rotateLeft().then(() => {
      this.canvas.saveState('rotateLeft');
    });
  }
}

export default FlipAndRotate;
