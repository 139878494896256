import {
  BorderSettings,
  CanvasConfig,
  MaskCoordinates,
  MaskPayload,
  MaskPosition,
  OutputFormat,
  TextSettings,
} from '@filestack/canvas-sdk';
import { ClientOptions, Filestack, PrefetchResponse } from 'filestack-js';


/**
 * Common button size
 *
 * @internal
 * @export
 * @enum {number}
 */
export enum ButtonSize {
  small = 'fs-btn-s',
  base = 'fs-btn-b',
  large = 'fs-btn-l',
}

/**
 * Common button type
 *
 * @internal
 * @export
 * @enum {number}
 */
export enum ButtonType {
  base = 'fs-btn-base',
  primary = 'fs-btn-primary',
  success = 'fs-btn-success',
  info = 'fs-btn-info',
  danger = 'fs-btn-danger',
}

export enum ViewMode {
  INLINE = 'inline',
  OVERLAY = 'overlay',
}

/**
 * Image config
 *
 * @private
 * @export
 * @interface ImageConfig
 */
export interface ImageConfig {
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  forceTransform: boolean;
  background: string;
}

/**
 * Applied crop position data interface
 *
 * @export
 * @interface AppliedCrop
 */
export interface AppliedCrop {
  top: number;
  left: number;
  width: number;
  height: number;
  radius?: number;
}

/**
 * Crop state object interface
 *
 * @internal
 * @export
 * @interface CropState
 */
export interface CropState {
  isApplied: boolean;
  appliedCrop?: AppliedCrop;
  selectedRatio: MaskPayload;
}

/**
 * Toast message type
 *
 * @internal
 * @export
 * @enum {number}
 */
export enum ToastMessageType {
  PRIMARY = 'primary',
  INFO = 'info',
  SUCCESS = 'success',
  DANGER = 'danger',
}

/**
 * Toast action
 *
 * @internal
 * @export
 * @interface ToastAction
 */
export interface ToastAction {
  title: string;
  callback: () => any;
}

/**
 * Toast message
 *
 * @internal
 * @export
 * @interface ToastMessage
 */
export interface ToastMessage {
  type: ToastMessageType;
  icon?: string;
  title?: string;
  message: string;
  params?: any;
  duration?: number;
  actions?: ToastAction[];
}

/**
 * Toast state
 *
 * @internal
 * @export
 * @interface ToastState
 */
export interface ToastState {
  messages: ToastMessage[];
  duration: number;
}

/**
 * OBase image state interface
 *
 * @export
 * @internal
 * @interface ImageState
 */
export interface ImageState {
  isReadonly?: boolean;
  isLoading: boolean;
  history: any[];
  background: string;
  isZoomLocked: boolean;
}

/**
 * Basic transform config interface
 *
 * @export
 * @interface TransformsConfig
 */
export interface TransformConfig {
  enabled: boolean;
  lockRotation: boolean;
  lockFlip: boolean;
  maskPosition?: MaskPosition;
  maskCoordinates?: MaskCoordinates;
  // rememberPreviousSelection: boolean;
  ratios: MaskPayload[];
}
/**
 * Available adjustments
 *
 * @export
 * @enum {number}
 */
export enum Adjustments {
  BRIGHTNESS = 'brightness',
  GAMMA = 'gamma',
  SATURATION = 'saturation',
  CONTRAST = 'contrast',
  HUE = 'hue',
  NOISE = 'noise',
  BLUR = 'blur',
  PIXELATE = 'pixelate',
}

/**
 * Config for adjustments
 *
 * @export
 * @interface AdjustmentsConfig
 */
export interface AdjustmentsConfig {
  enabled: boolean | Adjustments[];
}

/**
 * Available filters
 *
 * @export
 * @enum {number}
 */
export enum Filters {
  BLACK_WHITE = 'blackWhite',
  BROWNIE = 'brownie',
  KODACHROME = 'kodachrome',
  POLAROID = 'polaroid',
  SEPIA = 'sepia',
  TECHNICOLOR = 'technicolor',
  VINTAGE = 'vintage',
  ICE_COLD = 'iceCold',
  VANILLA_SKY = 'vanillaSky',
  BLUE_OCEAN = 'blueOcean',
  AUTUMN = 'autumn',
  MILKY_WAY = 'milkyWay',
}

/**
 * Custom filter
 *
 * @export
 * @interface CustomFilter
 */
export interface CustomFilter {
  id: any;
  displayName: string;
  matrix: number[];
}

export interface OverlaySettings {
  opacity?: number;
  fromSources?: string[];
}


/**
 * LutFilter
 *
 * @export
 * @interface CustomFilter
 */
export interface LutFilter {
  id: any;
  displayName: string;
  url: string;
}


/**
 * Filters config
 *
 * @export
 * @interface FiltersConfig
 */
export interface FiltersConfig {
  enabled: boolean | Filters[];
  custom?: CustomFilter[];
  lut?: LutFilter[];
}
/**
 * Font face config for fonts loader
 *
 * @export
 * @interface FontFace
 */
export interface FontFace {
  default?: boolean;
  displayName: string;
  family: string;
  url?: string;
  cssUrl?: string;
}

/**
 * Basic Top Manu config interface
 *
 * @export
 * @interface TopMenuConfig
 */
export interface TopMenuConfig {
  lockRotation: boolean;
  lockFlip: boolean;
  remove: boolean;
  layers: boolean;
}

/**
 * Tekst config interface
 *
 * @export
 * @interface TextConfig
 */
export interface TextConfig {
  enabled: boolean;
  defaults: TextSettings;
  fonts: FontFace[];
}

export interface BorderConfig {
  enabled: boolean;
  defaults: BorderSettings;
}

export interface OverlayConfig {
  enabled: boolean;
  defaults: OverlaySettings;
}

export interface EditorConfig {
  client: ClientOptions;
  closeOnSave?: boolean;
  showSave?: boolean;
  lang?: string;
  imageConfig?: ImageConfig;
  editor?: {
    topMenu: TopMenuConfig;
    transforms: TransformConfig;
    filters: FiltersConfig;
    adjustments: AdjustmentsConfig;
    text: TextConfig;
    border: BorderConfig;
    overlay: OverlayConfig;
  };
  output?: OutputFormat;
}

/**
 * Root state
 *
 * @export
 * @interface RootState
 */
export interface RootState {
  source: string | Blob | File;
  sdk?: typeof Filestack;
  viewMode: ViewMode;
  prefetch: PrefetchResponse;
  config: EditorConfig;
  canvas?: CanvasConfig;
}
