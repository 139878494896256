










import { Component, Vue, Prop } from 'vue-property-decorator';
import { ButtonType, ButtonSize } from '../types';

@Component
class FsButton extends Vue {

  @Prop()
  private type: keyof typeof ButtonType;

  @Prop()
  private disabled: boolean;

  @Prop()
  private micon: string;

  @Prop()
  private mobileicon: string;

  @Prop()
  private size: keyof typeof ButtonSize;

  private get iconContent() {
    return this.micon;
  }

  private handleClick(e: any) {
    if (this.disabled) {
      return false;
    }
    this.$emit('click', e);
  }

  get buttonClass() {
    const style: any = {};

    if (this.type && ButtonType[this.type]) {
      style[ButtonType[this.type]] = true;
    } else {
      style[ButtonType.base] = true;
    }

    if (this.size && ButtonSize[this.size]) {
      style[ButtonSize[this.size]] = true;
    } else {
      style[ButtonSize.base] = true;
    }

    if (this.mobileicon) {
      style['mobile-icon'] = true;
    }

    return {
      'fs-disabled': this.disabled,
      'fs-btn-icon': this.micon,
      ...style,
    };
  }
}

export default FsButton;
