




















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { adjustMenuPosition } from '@/helpers/menu';

import { routes } from '@/router';
import { getByName } from '@/helpers/router';
import { isActive } from '../helpers/router';

@Component
class Nav extends Vue {

  @Getter('image/isLocked')
  protected isImageLocked: boolean;

  @Getter('isBlocked')
  protected isAppBlocked: boolean;

  protected showOverlay: boolean = false;

  protected currentRoutePath: string = '/';

  @Getter('config')
  private config: any;

  get isSplitted(): boolean {
    return this.$route.meta && this.$route.meta.menuFrom;
  }

  get menuItems(): any {
    const routeData = getByName(this.$route.name, routes);

    if (!routeData) {
      return [];
    }

    let toReturn: any[] = [];

    if (routeData.meta && routeData.meta.menuFrom) {
      toReturn = getByName(routeData.meta.menuFrom, routes);
    } else {
      toReturn = getByName('main', routes).children;
    }


    return toReturn.filter((el: any) => !!el.meta);
  }

  protected parentRouteActive(route: any) {
    if (!route || !route.children || !Array.isArray(route.children)) {
      return;
    }

    const child = route.children.filter((el: any) => this.$router.currentRoute.path === el.path);

    return child.length > 0;
  }

  @Watch('$route')
  protected onRouteChange() {
    this.currentRoutePath = this.$router.currentRoute.path;
    Vue.nextTick(() => adjustMenuPosition(this.$root.$el));
  }

  protected isVisible(meta: any) {
    return isActive(meta, this.config) === false ? false : true;
  }
}

export default Nav;
