









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class FsRangeFilterWrapper extends Vue {

  @Prop()
  private value: number;

  @Prop()
  private min: number;

  @Prop()
  private max: number;

  @Prop()
  private defaultValue: number;

  @Prop()
  private label: string;

  @Prop()
  private disabled: boolean;

  @Prop()
  private routerLink: string;

  get valueModel(): number {
    return this.value || 0;
  }

  set valueModel(val) {
    this.$emit('input', +val);
  }
}

export default FsRangeFilterWrapper;
