






import { Adjustments } from '@filestack/canvas-sdk';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Pixelate extends FilterMixin {
  protected value = { blocksize: 1 };

  protected filter = new Adjustments.Pixelate();
}

export default Pixelate;
