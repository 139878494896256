
























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { Getter } from 'vuex-class';
import { getByName, isActive } from '@/helpers/router';
import { routes } from '@/router';

@Component
class BottomBarMenu extends Vue {

  @Getter('config')
  private config: any;

  @Getter('image/isLocked')
  private isImageLocked: boolean;

  get menuItems(): any {
    const routeData = getByName(this.$route.name, routes);

    if (!routeData) {
      return [];
    }

    let toReturn: any[] = [];

    if (routeData.meta && routeData.meta.bottomBarMenu) {
      toReturn = getByName(routeData.meta.bottomBarMenu, routes).children;
    }

    if (!toReturn) {
      return;
    }

    return toReturn.filter((el: any) => !!el.meta);
  }

  private isVisible(meta: any) {
    return isActive(meta, this.config) === false ? false : true;
  }
}

export default BottomBarMenu;
