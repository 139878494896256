
































import Vue from 'vue';
import { Inject, Component } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import debounce from 'lodash.debounce';

import { FsCanvas, CANVAS_EVENTS, OverlaySettings, OverlayLayer, AbstractLayer } from '@filestack/canvas-sdk';
import { Client, PickerOptions } from 'filestack-js';
import { checkImageUrl } from '@/helpers/image';
import { ToastMessage, ToastMessageType } from '@/types';
import { numbersSequence } from '@/helpers/array';

const DEBOUNCE_TIME = 250;

@Component
class OverlayBottom extends Vue {
  protected layerDebounceHandler: any;

  private settingsModel: OverlaySettings = {
    opacity: 100,
  };

  @Inject('canvas')
  private canvas: FsCanvas;

  @Inject('sdk')
  private sdk: Client;

  @Getter('config')
  private config: any;

  private selected: OverlayLayer = null;

  get overlayConfig() {
    // @ts-ignore @todo
    return this.config('editor.overlayConfig');
  }

  protected get numbersSequence() {
    return numbersSequence;
  }

  @Mutation('toast/show')
  private showToast: (data: ToastMessage) => void;


  private evHandler: any;

  private selectionChangedCallback(layer: AbstractLayer) {
    if (layer && layer instanceof OverlayLayer) {
      this.selected = layer;
      this.settingsModel = this.selected.getSettings();
    }
  }

  private mounted() {
    // @ts-ignore
    this.selected = this.$route.params.selected;
    if (this.selected instanceof OverlayLayer) {
      this.settingsModel = this.selected.getSettings();
    }

    this.canvas.on(CANVAS_EVENTS.LAYER_MODIFIED, this.onLayerModified);
    this.canvas.on(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);

    // setup initial dimensions
    document.addEventListener('keydown', this.evHandler);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.LAYER_MODIFIED, this.onLayerModified);
    this.canvas.off(CANVAS_EVENTS.SELECTION_CHANGED, this.selectionChangedCallback);
    document.removeEventListener('keydown', this.evHandler);
  }

  private openPicker() {
    const error = () => this.showToast({
      message: 'messages.overlays_error_loading',
      type: ToastMessageType.DANGER,
      icon: 'error',
    });

    const options: PickerOptions = {
      uploadInBackground: false,
      maxFiles: 1,
      accept: ['image/*'],
      imageMax: [2000, 2000],
      maxSize: 15 * 1024 * 1024,
      disableTransformer: true,
      onUploadDone: (res: any) => {
        if (res.filesUploaded && res.filesUploaded.length === 1) {
          return checkImageUrl(res.filesUploaded[0].url).then(() => {
            // this.canvas.overlay.addLayer(res.filesUploaded[0].url).then(() => this.canvas.saveState('overlayLayerAdd'));
            OverlayLayer.createFromURL(res.filesUploaded[0].url, {
              crossOrigin: 'anonymous',
            }).then((img: OverlayLayer) => {
                this.canvas.layer.add(img);
                this.canvas.saveState('overlayLayerAdd');
            });
          }).catch(error);
        }

        error();
      },
    };

    if (this.overlayConfig && this.overlayConfig.length) {
      options.fromSources = this.overlayConfig.fromSources;
    }

    this.sdk.picker(options).open();
  }

  private onChange() {
    // @ts-ignore
    this.selected.setOpacity(this.settingsModel.opacity);
    this.canvas.saveState('overlaySettingsUpdate');
  }

  private onLayerModified() {
    if (!this.layerDebounceHandler) {
      this.layerDebounceHandler = debounce(() => {
          this.canvas.saveState('overlayLayerModified');
      }, DEBOUNCE_TIME);
    }

    this.layerDebounceHandler();
  }
}

export default OverlayBottom;
