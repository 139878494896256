































import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';

import { Filters, FilterInterface, CustomFilter, FsCanvas, CANVAS_EVENTS, FilterPayload, FilterType, LutFilter  } from '@filestack/canvas-sdk';
import { Getter } from 'vuex-class';

const THUMB_SIZE = { width: 85, height: 85 };
const NO_FILTER_ID = 'no_filter';
const filtersList = Filters;

@Component
class FiltersMenu extends Vue {
  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  private urlCache: any = {};

  private filters: FilterInterface[] = [];

  private get activeId() {
    const active = this.canvas.filters.getItems(FilterType.SINGLE);

    if (active.length) {
      return active[0].id;
    }

    return null;
  }

  private get enabled() {
    return this.config('editor.filters.enabled');
  }

  private isThumbLoaded(payload: FilterInterface) {
    if (!this.urlCache || !this.urlCache.length || !this.urlCache[payload.getPayload().id]) {
      return false;
    }

    return true;
  }

  private isActive(name: string) {
    if (Array.isArray(this.enabled) && this.enabled.indexOf(name) > -1) {
      return true;
    }

    if (typeof this.enabled === 'boolean' && this.enabled) {
      return true;
    }

    return false;
  }

  private async created() {
    for (const filter in filtersList) {
      if (!filtersList.hasOwnProperty(filter)) {
        continue;
      }

      // @ts-ignore
      this.filters.push(new filtersList[filter]());
      this.generateThumbBase();
    }

    const customFilters = this.config('editor.filters.custom', []);
    customFilters.forEach(( filter: any ) => this.filters.push(new CustomFilter(filter.id, filter.displayName, filter.matrix)) );

    const lutFilters = this.config('editor.filters.lut', []);
    lutFilters.forEach(( filter: any ) => this.filters.push(new LutFilter(filter.id, filter.displayName, filter.url)) );
  }

  private mounted() {
    this.regenerateFilters();
    this.canvas.on(CANVAS_EVENTS.UNDO, this.regenerateFilters);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.regenerateFilters);
  }

  private regenerateFilters() {
    this.generateThumbBase().then(() => {
      this.generateThumbUrl(); // generates thumb without filter
      this.filters.forEach((payload: FilterInterface) => setTimeout(() => {
        this.generateThumbUrl(payload.getPayload());
      }));
    });
  }

  private async generateThumbBase(): Promise<any> {
    return this.canvas.thumbnails.init({
      width: THUMB_SIZE.width,
      height: THUMB_SIZE.height,
    });
  }

  private activateFilter(filter: FilterPayload) {
    this.canvas.filters.putItem(filter).render().then(() => {
      this.canvas.saveState('activateFilter');
    });
  }

  private deleteFilter() {
    this.canvas.filters.clear(FilterType.SINGLE).render().then(() => {
      this.canvas.saveState('clearFilters');
    });
  }

  private async generateThumbUrl(filterPayload?: FilterPayload): Promise<string> {
    const id = filterPayload ? filterPayload.id : NO_FILTER_ID;

    if (this.urlCache[id]) {
      return Promise.resolve(this.urlCache[id]);
    }

    return this.canvas.thumbnails.generateThumbUrl(filterPayload).then((url) => {
      this.$set(this.urlCache, id, url);
      return url;
    });
  }
}

export default FiltersMenu;
